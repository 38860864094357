<template>
  <div style="width: 100%">
      <keep-alive>
          <router-view v-if="$route.meta.isNeedKeepAlive" />
      </keep-alive>
      <router-view  v-if="!$route.meta.isNeedKeepAlive"/>
  </div>
</template>

<script>
export default {
  name: 'children',
  data () {
    return {
      isKeepAlive: false,
      transitionName: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('changeListKeepAlive', {isReset: true, isInitPageNo: true, isQueryList: true, isQuerySelect: true})
    })
  },
  beforeRouteUpdate(to, from, next) {
    if (to.meta.isNeedKeepAlive) {
      if (from.meta.isBackKeepAlive) {
        this.$store.commit('changeListKeepAlive', {isReset: false, isInitPageNo: false, isQueryList: true, isQuerySelect: true})
      } else {
        this.$store.commit('changeListKeepAlive', {isReset: true, isInitPageNo: true, isQueryList: true, isQuerySelect: true})
      }
    }
    next()
  },
  methods: {}
}
</script>
